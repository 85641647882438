import React, { useState } from 'react';
import { Menu, Drawer, Button, Dropdown, Space, message, Modal } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import { FaLocationDot, FaCircleUser, FaCartShopping, FaBuilding, FaCircleArrowRight } from "react-icons/fa6";
import { RiLogoutCircleLine } from "react-icons/ri";
import { BsBasket2Fill } from "react-icons/bs";
import logo from "../assets/images/final-logo.png";
import { useDispatch, useSelector } from 'react-redux';
import Cart from './Cart';
import { setCurrentUser, setuserLocationModal } from '../store/user/userSlice';
import { setCart, setCartPrice } from '../store/cart/cartSlice';

const Header = () => {

  const [visibleMenu, setVisibleMenu] = useState(false);
  const [visibleCart, setVisibleCart] = useState(false);
  const [isLogoutModalVisible, setIsLogoutModalVisible] = useState(false); // State for logout confirmation modal

  const showMenuDrawer = () => setVisibleMenu(true);
  const onCloseMenu = () => setVisibleMenu(false);

  const showCartDrawer = () => setVisibleCart(true);
  const onCloseCart = () => setVisibleCart(false);

  const showLogoutModal = () => setIsLogoutModalVisible(true); // Show logout confirmation modal
  const handleCancelLogout = () => setIsLogoutModalVisible(false); // Hide logout confirmation modal

  const { currentUser, userSelectedBranch } = useSelector(state => state.user);
  const { cartItems, cartTotalPrice } = useSelector(state => state.cart);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleConfirmLogout = () => {
    dispatch(setCurrentUser(null));
    dispatch(setCart([]));
    dispatch(setCartPrice(null));
    message.success("Logout Successfully");
    navigate("/");
    setIsLogoutModalVisible(false);
  };

  let items = [];

  if (currentUser) {
    items = [
      {
        key: '2',
        label: (
          <Link to="/myorders" className="flex items-center gap-2 text-[14px]">
            <BsBasket2Fill />
            <span>My Order</span>
          </Link>
        ),
      },
      {
        key: '3',
        label: (
          <Link to="/address" className="flex items-center gap-2 text-[14px]">
            <FaBuilding />
            <span>My Address</span>
          </Link>
        ),
      },
      {
        key: '5',
        label: (
          <div onClick={showLogoutModal} className="flex items-center gap-2 text-[14px]">
            <RiLogoutCircleLine />
            <span>Logout</span>
          </div>
        ),
      },
    ];
  } else {
    items = [
      {
        key: '1',
        label: (
          <Link to="/login" className="flex items-center gap-2 text-[14px]">
            <FaCircleUser />
            <span>Login</span>
          </Link>
        ),
      },
    ];
  }

  const showLocationModal = () => {
    dispatch(setuserLocationModal(true));
  };

  return (
    <header className="bg-white text-primary sticky top-0 z-[1000] pb-2 pt-4">
      <div className="container mx-auto flex justify-between items-center p-3 md:py-1 md:px-2">
        <Link to="/" className='cursor-pointer'>
          <img src={logo} alt="Alkhairi Fast Food" className='w-[70px] md:w-[110px]' />
          {/* <h3 className='font-bold text-[16px] text-primary playFont'>Al-Khairi Foods</h3> */}
        </Link>
        <div className='flex gap-1 items-center'>
          <FaLocationDot className='text-[30px]' />
          <p className='text-[12px] sm:text-sm cursor-pointer' onClick={showLocationModal}>
            <span className='block'>Deliver to</span>
            <span className='block'>{userSelectedBranch && userSelectedBranch.area.area_name + ", " + userSelectedBranch.city.city_name}</span>
          </p>
        </div>
        <div className="hidden lg:flex space-x-6">
          <Dropdown
            menu={{
              items,
            }}
            trigger={['click']}
          >
            <div onClick={(e) => e.preventDefault()} className='flex items-center gap-3'>
              <Space>
                <FaCircleUser className='text-[30px] cursor-pointer' />
              </Space>
              {currentUser && <p className='text-[16px] font-medium cursor-pointer'>{currentUser.fname}</p>}
            </div>
          </Dropdown>
          <div className="relative">
            <FaCartShopping className='text-[30px] cursor-pointer' onClick={showCartDrawer} />
            <span className="absolute top-[-15px] right-[-10px] bg-primary font-medium text-white border-primary border-2 rounded-full text-sm w-5 h-5 flex items-center justify-center">{cartItems && cartItems.length}</span>
          </div>
        </div>

        <Button
          className="lg:hidden"
          type="primary"
          icon={<MenuOutlined />}
          onClick={showMenuDrawer}
        />
      </div>
      <Drawer title="Menu" placement="right" onClose={onCloseMenu} width={200} open={visibleMenu}>
        {currentUser &&
          <div className='flex justify-center items-center gap-3 my-4'>
            <Space>
              <FaCircleUser className='text-[20px] cursor-pointer' />
            </Space>
            <p className='text-[14px] font-medium cursor-pointer'>{currentUser.fname}</p>
          </div>}
        <Menu mode="vertical" items={items} />
        <hr className="mt-4 h-[1px] bg-gray-400" />
      </Drawer>
      <div className='cart-drawer'>
        <Cart onCloseCart={onCloseCart} visibleCart={visibleCart} />
      </div>
      {cartItems.length > 0 &&
        <div className='lg:hidden flex justify-between items-center gap-3 px-5 py-2 bg-primary w-full text-white text-center fixed bottom-0 mt-4'>
          <div>
            <p className='text-[10px] m-0 p-0 leading-none'>item {cartItems.length}</p>
            <p className='text-[13px] font-medium mt-1  p-0 leading-none'>Rs {cartTotalPrice}</p>
          </div>
          <div>
            <div className="flex gap-2 item-center cursor-pointer" onClick={showCartDrawer}>
              <p className='font-medium leading-none p-0 m-0'>View Cart</p>
              <FaCircleArrowRight />
            </div>
          </div>
        </div>
      }

      <Modal
        title="Logout Confirmation"
        open={isLogoutModalVisible}
        onOk={handleConfirmLogout}
        onCancel={handleCancelLogout}
        okText="Yes"
        cancelText="No"
      >
        <p className='font-medium'>Are your sure do you want to logout?</p>

      </Modal>
    </header>
  );
};

export default Header;

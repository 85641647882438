import React from 'react';
import logo from "../assets/images/final-logo.png"
import { Link } from 'react-router-dom';
import { FaFacebook, FaInstagram, FaTiktok } from "react-icons/fa";
import Container from './Container';

const Footer = () => {
    return (
        <Container>
            <footer className="bg-white pb-8 pt-12 rounded-xl border-2 border-gray-200 shadow-sm max-md:mb-8">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    {/* <div className="grid grid-cols-12 gap-4">
                        <div className="col-span-12 md:col-span-6">
                            <img src={logo} alt="boltwireless" className='w-[130px]' />
                            <p className='mt-4 font-light'>Welcome to Alkhairi Fast Food Delivery, your go-to spot for delicious, quick, and convenient meals. Enjoy our wide variety of freshly prepared dishes, delivered straight to your doorstep. Quality food, exceptional service, every time!</p>
                            <ul className='flex gap-6 my-4'>
                                <li><Link to="/" target='blank'><FaFacebook className='text-[24px] text-themeColor' /></Link></li>
                                <li><Link to="/" target='blank'><FaXTwitter className='text-[24px] text-themeColor' /></Link></li>
                                <li><Link to="/" target='blank'><FaYoutube className='text-[24px] text-themeColor' /></Link></li>
                            </ul>
                        </div>
                        <div className='col-span-12 md:col-span-1'></div>
                        <div className="col-span-12 sm:col-span-6 md:col-span-2">
                            <h3 className='text-[18px] font-bold text-themeColor mb-4'>COMPANY</h3>
                            <ul>
                                <li className='mb-2 hover:text-themeColor'><Link>About Us</Link></li>
                                <li className='mb-2 hover:text-themeColor'><Link>Contact Us</Link></li>
                                <li className='mb-2 hover:text-themeColor'><Link>Services</Link></li>
                                <li className='mb-2 hover:text-themeColor'><Link>Products</Link></li>
                            </ul>
                        </div>
                        <div className="col-span-3">
                            <h3 className='text-[18px] font-bold text-themeColor mb-4'>HELP</h3>
                            <ul>
                                <li className='mb-2 hover:text-themeColor'><Link>About Us</Link></li>
                                <li className='mb-2 hover:text-themeColor'><Link>Contact Us</Link></li>
                                <li className='mb-2 hover:text-themeColor'><Link>Services</Link></li>
                                <li className='mb-2 hover:text-themeColor'><Link>Help</Link></li>
                            </ul>
                        </div>
                    </div> */}
                    <div className="flex justify-center items-center text-center w-full">
                        <div>
                            <div className='flex justify-center'>
                                <img src={logo} alt="boltwireless" className='w-[130px]' />
                                {/* <h3 className='font-bold text-[16px] text-primary playFont'>Al-Khairi Foods</h3> */}

                            </div>
                            <p className='mt-4 font-light px-[15%]'>Welcome to Alkhairi Fast Food Delivery, your go-to spot for delicious, quick, and convenient meals. Enjoy our wide variety of freshly prepared dishes, delivered straight to your doorstep. Quality food, exceptional service, every time!</p>
                            <ul className='flex gap-6 my-4 justify-center'>
                                <li><Link to="https://www.facebook.com/profile.php?id=61561993988608" target='blank'><FaFacebook className='text-[24px] text-themeColor' /></Link></li>
                                <li><Link to="https://www.instagram.com/?hl=en" target='blank'><FaInstagram className='text-[24px] text-themeColor' /></Link></li>
                                <li><Link to="https://www.tiktok.com/@alkhairifood?lang=en" target='blank'><FaTiktok className='text-[24px] text-themeColor' /></Link></li>
                            </ul>
                        </div>
                    </div>
                    <hr className="my-6 border-themeColor sm:mx-auto dark:border-gray-700 lg:my-8" />
                    <span className="block text-sm text-gray-500 sm:text-center dark:text-gray-400 text-center">© {new Date().getFullYear()} <a href="/" target='black' className="hover:underline">Alkhairi Fast Food™</a>. All Rights Reserved.</span>
                </div>
            </footer>
        </Container>
    );
};

export default Footer;

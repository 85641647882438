import React, { memo, useState } from 'react';
import { Button } from 'antd';
import ProductModal from './ProductModal';

const ProductCard = ({ product }) => {

    const [modalVisible, setModalVisible] = useState(false);

    const showModal = () => {
        setModalVisible(true);
    };

    const hideModal = () => {
        setModalVisible(false);
    };

    return (
        <div>
            <div onClick={showModal} className='p-2 cursor-pointer bg-white rounded-2xl border-2 border-gray-100 shadow-sm hover:border-primary duration-300 flex flex-col justify-between h-full'>
                <img src={product.f_image} alt="productImg" className='w-full rounded-xl' />
                <div className="p-1 sm:p-2 flex flex-col justify-between h-full">
                    <div className="text-center">
                        <p className='my-2 text-[14px] sm:text-[18px] font-medium text-gray-800'>{product.f_name}</p>
                        <p className='my-2 text-[10px] sm:text-[12px] leading-[1.4] text-gray-800'>{product.f_details}</p>
                    </div>
                    <div className="text-center mt-auto">
                        <p className='font-bold text-primary p-0 my-1 text-[18px] relative'>Rs {product.f_price} {product.old_price !== "0" && <del className='text-gray-800 text-[13px] absolute top-0 right-0 md:right-6 lg:right-12'>{product.old_price}</del>} </p>
                        <Button type='primary' className='rounded-full my-1' onClick={showModal}>View Details</Button>
                    </div>
                </div>
            </div>
            <ProductModal open={modalVisible} product={product} hideModal={hideModal} />
        </div>
    );
};

export default memo(ProductCard);

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Carousel } from 'antd';
import CatProducts from '../components/CatProducts';
import Container from '../components/Container';
import Herosection from '../components/Herosection';
import { Link } from 'react-scroll';
import FAQ from '../components/FAQ';
import LocationModal from '../components/LocationModal';
import Preloader from '../components/Preloader';

const Home = () => {

    const categories = useSelector(state => state.product.allCategories) || [];
    const [selectedCategory, setSelectedCategory] = useState(null);

    const handleCategorySelect = (category) => {
        setSelectedCategory(selectedCategory === category ? null : category);
    };

    const renderCarouselItems = () => {
        const itemsPerSlide = 3; // Adjust this number based on your design needs
        const totalSlides = Math.ceil(categories.length / itemsPerSlide);
        const slides = [];

        for (let i = 0; i < totalSlides; i++) {
            const start = i * itemsPerSlide;
            const end = start + itemsPerSlide;
            const categoriesInSlide = categories.slice(start, end);

            // Pad with null elements to ensure each slide has exactly `itemsPerSlide` items
            while (categoriesInSlide.length < itemsPerSlide) {
                categoriesInSlide.push(null);
            }

            slides.push(
                <div key={i} className="flex justify-around">
                    {categoriesInSlide.map((category, index) => (
                        category ? (
                            <Link to={`${category.c_name}`}
                                spy={true}
                                smooth={true}
                                offset={-170}
                                duration={800}
                                key={category.c_id}
                                className={`cursor-pointer rounded-full px-3 py-2 mr-2 my-3 ${selectedCategory === category.c_name ? "bg-primary text-white" : "bg-[#E5E5E5] text-primary"}`}
                                size="large"
                                onClick={() => handleCategorySelect(category.c_name)}
                            >
                                {category.c_name}
                            </Link>
                        ) : (
                            <div key={index} className="flex items-center justify-center" style={{ width: 120 }}>
                                {/* Placeholder for empty space in the carousel */}
                            </div>
                        )
                    ))}
                </div>
            );
        }

        return slides;
    };

    if (!categories || categories.length === 0) {
        return <Preloader />;
    }

    return (
        <div>
            <Herosection />
            <Container>
                {/* product categories  */}
                <div className='productWithCategory'>
                    <div className="sticky top-[94px]  md:top-[105px] z-[999] border-t border-gray-400">
                        <div className="p-0 bg-white rounded-b-lg shadow-sm">
                            <div className="hidden md:flex md:flex-wrap justify-center">
                                {categories.map((category) => (
                                    <Link to={`${category.c_name}`}
                                        spy={true}
                                        smooth={true}
                                        offset={-170}
                                        duration={800}
                                        key={category.c_id}
                                        className={`cursor-pointer rounded-full px-6 py-2 mr-2 my-3 ${selectedCategory === category.c_name ? "bg-primary text-white" : "bg-[#E5E5E5] text-primary"}`}
                                        size="large"
                                        onClick={() => handleCategorySelect(category.c_name)}
                                    >
                                        {category.c_name}
                                    </Link>
                                ))}
                            </div>
                            <div className="md:hidden">
                                {/* Render as carousel items in mobile view */}
                                <Carousel autoplay={false} dots={false} draggable={true}>
                                    {renderCarouselItems()}
                                </Carousel>
                            </div>
                        </div>
                    </div>

                    {/* Category Products */}
                    <div className='my-8'>
                        {categories.map((cat) => (
                            <div id={cat.c_name} key={cat.c_id}>
                                <CatProducts cat={cat} />
                            </div>
                        ))}
                    </div>
                </div>
                <div>
                    {/* show Filterd product based on search  */}
                </div>
                <FAQ />
            </Container>
            <LocationModal />
        </div>
    );
};

export default Home;

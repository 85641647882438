import { Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './screens/Home';
import Layout from './Layout';
import useGet from "./hooks/useGet";
import { useEffect } from 'react';
import PreLoader from "./components/Preloader";
import { useDispatch, useSelector } from 'react-redux';
import { setAllCategories, setAllProducts } from './store/product/productSlice';
import Checkout from './screens/Checkout';
import ScrollToTop from './components/ScrollToTop';
import Login from "./screens/Login";
import Register from './screens/Register';
import OrderDetails from './screens/OrderDetails';
import axios from 'axios';
import { setUserAddress } from './store/user/userSlice';
import MyAddress from './screens/MyAddress';
import MyOrders from './screens/MyOrders';
import NotFound from './components/NotFound';
import Protected from './components/Protected';
import AdminPanel from './screens/AdminPanel';
import SingleOrderDetails from './screens/SingleOrderDetails';

function App() {

  const { data: categories, loading: catLoading } = useGet("/categories.php");
  const { data: products, loading: prodLoading } = useGet("/products.php");
  const { currentUser, isGuest } = useSelector(state => state.user);

  const dispatch = useDispatch();

  useEffect(() => {
    if (categories && products) {
      const filteredCat = categories.filter(cat => +cat.product_count !== 0);
      dispatch(setAllProducts(products));
      dispatch(setAllCategories(filteredCat));
    }
  }, [products, categories, dispatch]);

  useEffect(() => {
    (async () => {
      if (currentUser && !isGuest) {
        try {
          const latestAddresses = await axios.get(`${process.env.REACT_APP_BASE_URL}/addresse.php?cid=${currentUser.id}`);
          dispatch(setUserAddress(latestAddresses.data));
        } catch (error) {
          console.log(error);
        }
      }
    })()
  }, [currentUser, dispatch, isGuest]);

  if (catLoading || prodLoading) {
    return <PreLoader />
  }

  // app routes 


  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path='/' element={currentUser && currentUser.aid === 1 ? <AdminPanel /> : <Layout />}>
          <Route index element={<Home />} />
          <Route path="checkout" element={<Checkout />} />
          {currentUser &&
            <>
              <Route path="address" element={<Protected component={<MyAddress />} />} />
              <Route path="myorders" element={<Protected component={<MyOrders />} />} />
            </>
          }
        </Route>
        <Route path="login" element={<Login />} />
        <Route path="register" element={<Register />} />
        <Route path="orderdetails/:id" element={<OrderDetails />} />
        {currentUser && currentUser.aid === 1 &&
          <>
            <Route path="singleorder/:id" element={<SingleOrderDetails />} />
          </>
        }
        <Route path='*' element={<NotFound />} />
      </Routes>
    </>
  );
}

export default App;

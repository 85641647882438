import React, { useState, useEffect } from 'react';
import { Modal, Button, Select } from 'antd';
import logo from "../assets/images/final-logo.png";
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedBranch, setuserLocationModal } from '../store/user/userSlice';
import useGet from '../hooks/useGet';

const { Option } = Select;

const LocationModal = () => {
    const [filteredAreas, setFilteredAreas] = useState([]);
    const dispatch = useDispatch();
    const { userSelectedBranch, userLocationModal } = useSelector(state => state.user);
    const [selectedCity, setSelectedCity] = useState(null);
    const [selectedArea, setSelectedArea] = useState(null);
    const { data: cities = [], loading: loadingCities, error: errorCities } = useGet("/cities.php");
    const { data: areas = [], loading: loadingAreas, error: errorAreas } = useGet("/areas.php");

    useEffect(() => {
        if (!userSelectedBranch) {
            dispatch(setuserLocationModal(true));
        } else {
            setSelectedCity(userSelectedBranch.city);
            setSelectedArea(userSelectedBranch.area);
            setFilteredAreas(areas.filter(area => area.city_id === userSelectedBranch.city.city_id));
        }
    }, [userSelectedBranch, dispatch, areas]);

    const handleCityChange = (value) => {
        const selectedCityObj = cities.find(city => city.city_id === value);
        setSelectedCity(selectedCityObj);
        const filtered = areas.filter(area => area.city_id === value);
        setFilteredAreas(filtered);
        setSelectedArea(null); // Reset selected area when city changes
    };

    const handleAreaChange = (value) => {
        const selectedAreaObj = areas.find(area => area.area_id === value);
        setSelectedArea(selectedAreaObj);
    };

    const handleOk = () => {
        if (selectedCity && selectedArea) {
            dispatch(setSelectedBranch({ city: selectedCity, area: selectedArea }));
            dispatch(setuserLocationModal(false));
        } else {
            // Handle case where city or area is not selected
            console.error('Please select both city and area');
        }
    };

    const handleCancel = () => {
        dispatch(setuserLocationModal(false));
    };

    return (
        <Modal
            open={userLocationModal}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={null}
            centered
            closeIcon={!userSelectedBranch ? null : true}
            maskClosable={false}
        >
            <div className="p-6 bg-white rounded-lg text-center">
                <div className="mb-4">
                    <img
                        src={logo}
                        alt="Logo"
                        className="mx-auto w-[150px]"
                    />
                    {/* <h3 className='font-bold text-[16px] text-primary playFont'>Al-Khairi Foods</h3> */}

                </div>
                <h2 className="text-lg font-bold mb-2">Please Select Your Location</h2>
                <Select
                    className="w-full mb-4"
                    placeholder="Select City / Region"
                    onChange={handleCityChange}
                    value={selectedCity ? selectedCity.city_id : undefined}
                    loading={loadingCities}
                    disabled={loadingCities || errorCities}
                >
                    {cities.map(city => (
                        <Option key={city.city_id} value={city.city_id}>
                            {city.city_name}
                        </Option>
                    ))}
                </Select>
                <Select
                    className="w-full mb-4"
                    placeholder="Select Area / Sub Region"
                    disabled={!selectedCity || loadingAreas || errorAreas}
                    onChange={handleAreaChange}
                    value={selectedArea ? selectedArea.area_id : undefined}
                >
                    {filteredAreas.map(area => (
                        <Option key={area.area_id} value={area.area_id}>
                            {area.area_name}
                        </Option>
                    ))}
                </Select>
                <Button type="primary" className="w-full" onClick={handleOk}>
                    Select
                </Button>
            </div>
        </Modal>
    );
};

export default LocationModal;

import React, { memo, useEffect, useState } from 'react'
import ProductCard from './ProductCard'
import { useSelector } from 'react-redux';

const CatProducts = ({ cat }) => {

    const { allProducts } = useSelector(state => state.product);
    const [filteredProducts, setFilteredProducts] = useState([]);

    useEffect(() => {
        if (allProducts) {
            setFilteredProducts(
                allProducts.filter(product => product.cid === cat.c_id)
            )
        }
    }, [allProducts, cat])

    return (
        <div className='mb-6 sm:mb-12'>
            <h3 className='text-2xl md:text-3xl font-bold text-gray-700 my-4'>{cat.c_name}</h3>
            <div className="grid grid-cols-2 gap-4 sm:grid-cols-3 lg:grid-cols-4 my-4 pb-4 border-b border-gray-300">
                {filteredProducts.length && filteredProducts.map((product) => (
                    <ProductCard key={product.f_id} product={product} />
                ))}
            </div>
        </div>
    )
}

export default memo(CatProducts);
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    currentUser: null,
    userSelectedBranch: null,
    userLocationModal: false,
    isGuest: false,
    userAddresses: [],
    myOrders: [],
}

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setCurrentUser: (state, action) => {
            state.currentUser = action.payload;
        },
        setSelectedBranch: (state, action) => {
            state.userSelectedBranch = action.payload;
        },
        setuserLocationModal: (state, action) => {
            state.userLocationModal = action.payload;
        },
        setIsGuest: (state, action) => {
            state.isGuest = action.payload;
        },
        setUserAddress: (state, action) => {
            state.userAddresses = action.payload;
        },
        setMyOrders: (state, action) => {
            state.myOrders = action.payload;
        }
    }
});

export const { setCurrentUser, setSelectedBranch, setuserLocationModal, setIsGuest, setUserAddress, myOrders } = userSlice.actions;
export default userSlice.reducer;

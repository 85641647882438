import React from 'react';
import heroImg from "../assets/images/hero-bg.jpg";

const Herosection = () => {
    return (
        <div className='relative h-[60vh] md:h-[80vh] bg-cover bg-center w-full'>
            {/* Background Image */}
            <div className="absolute inset-0 w-full" style={{ backgroundImage: `url(${heroImg})`, zIndex: -1, filter: 'brightness(0.9)' }}></div>

            {/* Overlay */}
            <div className="absolute inset-0 bg-[#100A08] opacity-85 w-full h-full"></div>

            {/* Content */}
            <div className="relative z-10 flex items-center justify-center h-full w-full">
                <h1 className="text-white text-3xl sm:text-4xl mt-4 md:text-5xl font-bold tracking-wide text-center leading-[1.5] md:leading-[1.5] playFont">
                    Delicious Fast Food<br />Delivered to Your Doorstep
                </h1>
            </div>
        </div>
    );
};

export default Herosection;
